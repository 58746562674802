<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>/
            
            <router-link :to="{name: 'rolelink' }" class="root">
               Librarian
            </router-link>/
                        <router-link :to="{name: 'management-reports' }" class="root">
               Reports
            </router-link>/
            <span class="child">Accession Report </span>
        </v-row>
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Accession Register For Collage</v-card-title>
            <div class="checkbox">
                <v-row style="margin:0px 10px 0px 10px">
                    <v-col><v-checkbox v-model="selected" label="Accession No" value="AccessionNo"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Entry Date" value="EntryDate"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Title" value="Title"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Author" value="Author"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Edition" value="Edition"></v-checkbox></v-col>
                </v-row >
                                <v-row  style="margin:0px 10px 0px 10px">
                    <v-col><v-checkbox v-model="selected" label="Book Format" value="BookFormat"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Book Type" value="BookType"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Category" value="Category"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Publisher" value="Publisher"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Volume" value="Volume"></v-checkbox></v-col>
                </v-row>
                                                <v-row  style="margin:0px 10px 0px 10px">
                    <v-col><v-checkbox v-model="selected" label="Pages" value="Pages"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="ISBN" value="ISBN"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Source Bill No" value="BillNo"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Book Cost" value="BookCost"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Book Discount" value="BookDiscount"></v-checkbox></v-col>
                </v-row>
                                                                <v-row  style="margin:0px 10px 0px 10px">
                    <v-col><v-checkbox v-model="selected" label="Book Net Amount" value="netAmt"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Organization" value="org"></v-checkbox></v-col>
 <v-col></v-col> <v-col></v-col> <v-col></v-col>
                   
                    <!-- <v-col><v-checkbox v-model="selected" label="ISBN" value="BookType"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Source Bill No" value="Category"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Book Cost" value="Publisher"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Book Discount" value="Volume"></v-checkbox></v-col> -->
                </v-row>
                <v-row class="justify-center">
                    <v-col cols="5" >
                        <label>Organization</label>
                        <v-combobox v-model="selected_org" placeholder="Select Organization" class="text" :items="orgList" item-text="name" item-value="id" outlined return-object dense :rules="[rules.required]" clearable required ></v-combobox>
</v-col>
                </v-row>
               <center> <v-btn dense color="info" @click="fetch" style="margin:10px">Fetch</v-btn></center>
            </div>
        <div v-if="dataAvailable" ><center>
            <p style="color:red">No data available for selected organization!!!!</p></center>
        </div>
            <div class="d-btn" v-if="filteredDesserts.length > 0">
                <div>
                    <v-btn color="primary" @click="generatePDF"><span class="mdi mdi-download mr-2" style="font-size: 20px;"></span> Download Report</v-btn>
                </div>
            </div>
            <div class="" v-if="filteredDesserts.length > 0">
                <v-data-table v-model="selected" @current-items="getFiltered" :headers="headers" :items="filteredDesserts" item-key="title" class="elevation-1">
                    <v-spacer></v-spacer>

                    <template v-slot:top>
                        <tr class="t-row">
                            <div>
                                <th class="d-flex">
                                    <span v-for="header in headers" :key="header.text">
                                        <span v-if="filters.hasOwnProperty(header.value)">
                                            <v-autocomplete class="m-2" flat hide-details small dense outlined :label="header.text" multiple clearable :items="columnValueList(header.value)" v-model="filters[header.value]"></v-autocomplete>
                                        </span>
                                    </span>
                                </th>
                            </div>
                        </tr>
                    </template>

                    <template v-slot:item.author="{ item }">
                        <span v-for="author in item.authors" :key="author">{{author}}<br /></span>
                    </template>
                    <template v-slot:item.costall="{ item }">
                        <span>{{item.cost}},{{item.discount}}%,{{item.purchase_price}}<br /></span>
                    </template>
                </v-data-table>
                <!-- <div id="content">my name here</div> -->
            </div>
        </v-card>
    </div>
</template>

<script>
    import axios from "axios";
    import { jsPDF } from "jspdf";
    import "jspdf-autotable";

    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            selected_org:null,
            orgList:[],
            valid: false,
            singleExpand: false,
            expanded: [],
            overlay: false,
            userid: "",
            bookitemid: "",
            errorMsg: {
                roleLinkId: false,
            },
            rules: {
                required: (value) => !!value || "Required.",
            },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            accessionNoList: [],
            book_info: [],
            bookinformation: [],
            empmemberbooklendinginfo: [],
            allBookInfo: [],
            accessionNo: "",
            selectedbookitem: "",
            selected: [],
            org: "",
            inSave: true,
            dialog: false,
            bookCategoryList: [],
            roleLink_list: [],
            role_list: [],
            userInfo: false,
            bookInfo: false,
            dataAvailable:false,
            reportdata: [],
            csv_header_data:[],
            table_header_data:[],

            pagination: {
                sortBy: "name",
            },
            headers: [
                {
                    text: "srno",
                    align: "left",
                    value: "srno",
                },
                { text: "Date of Entry", value: "entry_date" },
                { text: "Accession No", value: "acc_no" },
                { text: "Title", value: "title" },
                { text: "Author", value: "author" },

                { text: "Edition", value: "edition" },
                { text: "Edition Year", value: "edition_year" },
                { text: "Publisher", value: "pub_year" },
                { text: "Pages", value: "pages" },

                { text: "bill_no", value: "bill_no" },
                { text: "cost", value: "costall" },
            ],

            filters: {
                acc_no: [],
authors:[] ,
bill_no:[] ,
book_cat:[] ,
book_format:[] ,
book_type:[] ,
cost:[],
discount:[],
edition:[] ,
entry_date:[] ,
isbn:[] ,
pages:[] ,
pub_year:[],
purchase_price:[],
srno:[] ,
title:[] ,
volume:[] ,

            },

            heading: "Accession Register",
            fetchData:{},
            // filters:{}
        }),

        computed: {
            filteredDesserts() {
                return this.reportdata.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                    });
                });
            },
        },

        mounted() {
            // this.onLoad();
            this.getOrg();
        },

        methods: {
            getOrg(){
                axios
                    .get("/ManagementAdmin/getOrg")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.orgList = res.data.orgList
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            fetch(){
 this.onLoad();
            },
            generatePDF() {
                const columns = this.csv_header_data
                const doc = new jsPDF({
                    orientation: "l",
                    unit: "in",
                    format: "letter",
                });

                // text is placed using x, y coordinates
                //   var img = 'https://www.vierp.in/logo/edu+camp-01.png'
                //   doc.addImage(img,'PNG', 0, 0, 180, 160);
                doc.setFontSize(10).text("Pimpri Chinchwad Education Trust", 0.5, 0.3);
                doc.setFontSize(12).text("Pimpri Chinchwad College of Engineering", 0.5, 0.5);
                doc.setFontSize(12).text("Central Library", 0.5, 0.7);

                doc.setFontSize(10).text("Record No", 8, 0.3);
                doc.setFontSize(10).text("Revision", 8, 0.5);
                doc.setFontSize(10).text("Date", 8, 0.7);

                doc.setFontSize(18).text(this.heading, 0.5, 1.0);
                doc.setTextColor("blue").text(this.heading, 0.5, 1.0);
                doc.setLineWidth(0.01).line(0.5, 1.1, 10.4, 1.1);
                doc.autoTable({
                    columns,
                    body: this.filteredDesserts,
                    margin: { left: 0.5, top: 1.25 },
                });

                doc.setTextColor(0, 0, 255).save(`${this.heading}.pdf`);
            },

            getFiltered(e) {
                console.log(e);
                if(e?.length == 0)
                {
                    this.showSnackbar("#b71c1c", "No Record matching with your input/ filter"); 
                }
                this.filterArr = e;
            },
            
            onlyUnique(value, index, self) {
                if(value != null && value != "" && value)
                    return self.indexOf(value) === index;
            },

            columnValueList(val) {
                var data = this.bookList.map((d) => d[val]);
                data = data.filter(this.onlyUnique);
                return data
            },
            onLoad() {
                this.overlay = true;
                let abc = Object.assign({}, this.selected);
                this.fetchData = {}
                for(let i = 0;i<this.selected.length;i++){
                    let key = abc[i]
                    this.fetchData[key] = key
                }

                console.log(this.selected_org);
                if(this.selected_org == null){
                                        this.showSnackbar("#b71c1c", "Please select Organization"); // show snackbar

                }else{
                this.fetchData.org = this.selected_org.id

                axios
                    .post("/ManagementAdmin/accessionReport",this.fetchData)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.reportdata = res.data.report_data;
                            if(this.reportdata.length >0){
                                this.dataAvailable = false
                            }else{
                                this.dataAvailable =true
                            }
                            this.csv_header_data = res.data.csv_header_data
                            this.table_header_data = res.data.table_header_data
                            this.headers = this.table_header_data

                            for(let i = 0;i<this.table_header_data.length;i++){
                                let key = this.table_header_data[i].value
                                this.filters[key] = []
                            }
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
               }
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 0rem 0.5rem 0.5rem 0.5rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .checkbox /deep/ .v-input--selection-controls{
        margin-top: 0px  !important;
    }
    .d-btn {
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem;
    }
    .checkbox{
        margin: 1rem;
    border: 1px solid;
    background: #d3d3d380;
    }
    .checkbox .box{
        margin-top: 5px;
    }
    .text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
